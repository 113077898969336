import React, { ReactElement } from 'react';

import { Flex, Heading, Text } from '@chakra-ui/react';
import { GetStaticProps } from 'next';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

import { DefaultLayout, Footer } from '@/components/layouts';
import { PolishLogos } from '@/components/molecules/PolishLogos/PolishLogos';
import { LetsBuildSomethingSection } from '@/components/sections/common';
import { SectionContentWrapper, SectionWrapper } from '@/components/wrappers';
import { PropsBuilder } from '@/graphql/api';
import { useGetGlobalQuery } from '@/graphql/generated';
import { NextPageWithLayout } from '@/pages/_app';
import { DEFAULT_LOCALE, LOCALES, PAGE_REVALIDATE_TIME } from '@/utils/constants';

export const SDHLabs: NextPageWithLayout = () => {
  const { t } = useTranslation([LOCALES.SDH_LABS]);
  return (
    <>
      <SectionWrapper>
        <SectionContentWrapper display="flex" justifyContent="center">
          <Flex justifyContent="center" flexDir="column" alignItems="center" maxW="1280px">
            <Heading
              as="h1"
              w={{
                base: '100%',
                md: '80%',
              }}
              textAlign="center"
              color="purple.500"
              variant={{
                base: 'component-heading-small/lg',
                sm: 'component-heading-large/lg',
                md: 'component-heading-large/xl',
              }}
            >
              {t('heading')}
            </Heading>
            <PolishLogos showPolandLogo={true} mt={20} mb={16} />
            <Text
              mb={16}
              variant={{
                base: 'text-md/lineHeight-6/font-normal',
                sm: 'text-xl/lineHeight-7/font-normal',
              }}
              color="purple.500"
              textAlign="center"
            >
              {t('info.0')} <strong>{t('info.1')}</strong> {t('info.2')}
            </Text>
            <Text textAlign="center" mb={16} color="purple.500">
              {t('finance.0')} <br />
              {t('finance.1')}
            </Text>
            <Heading
              as="h2"
              mb={10}
              variant={{
                base: 'component-heading-small/lg',
                sm: 'component-heading-large/lg',
                md: 'component-heading-large/xl',
              }}
              color="purple.500"
              width="full"
              textAlign={{
                base: 'left',
                xs: 'center',
              }}
            >
              {t('generalInfo')}
            </Heading>
            <Text
              as="div"
              variant={{
                base: 'text-md/lineHeight-6/font-normal',
                sm: 'text-xl/lineHeight-7/font-normal',
              }}
              color="purple.500"
              sx={{
                p: {
                  mb: 3,
                },
                strong: {
                  display: 'block',
                },
              }}
              textAlign={{
                base: 'left',
                xs: 'center',
              }}
            >
              <Text>
                <strong>{t('data.labels.0')}</strong>
                {t('data.values.0')}
              </Text>
              <Text>
                <strong>{t('data.labels.1')}</strong>
                {t('data.values.1')}
              </Text>
              <Text>
                <strong>{t('data.labels.2')}</strong>
                {t('data.values.2')}
              </Text>
              <Text>
                <strong>{t('data.labels.3')}</strong>
                {t('data.values.3')}
              </Text>
              <Text>
                <strong>{t('data.labels.4')}</strong>
                {t('data.values.4')}
              </Text>
              <Text>
                <strong>{t('data.labels.5')}</strong>
                {t('data.values.5')}
              </Text>
              <Text>
                <strong>{t('data.labels.6')}</strong>
                {t('data.values.6')}
              </Text>
              <Text>
                <strong>{t('data.labels.7')}</strong>
                {t('data.values.7')}
              </Text>
            </Text>
          </Flex>
        </SectionContentWrapper>
      </SectionWrapper>
      <LetsBuildSomethingSection overflow={false} />
    </>
  );
};

SDHLabs.getLayout = (page: ReactElement) => {
  const { metadata } = page.props;

  return (
    <DefaultLayout metadata={metadata}>
      {page}
      <Footer />
    </DefaultLayout>
  );
};

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  const propsBuilder = new PropsBuilder();

  propsBuilder.withQuery(useGetGlobalQuery, {
    variables: {
      locale,
    },
  });

  const lang = await serverSideTranslations(locale || DEFAULT_LOCALE, [LOCALES.COMMON, LOCALES.SDH_LABS]);

  return {
    props: await propsBuilder.build(lang),
    revalidate: PAGE_REVALIDATE_TIME,
  };
};

export default SDHLabs;
